<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="1000px"
            :center="true"
            top="50px"
            :close-on-click-modal="false"
            :before-close="() => closeShopArticleDialog('ShopArticleEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="ShopArticle"
                    ref="ShopArticleEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-descriptions
                            direction="vertical"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center','width':'50%'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>物品图片</span>
                                </template>
                                <common-upload-component
                                    ref="upload"
                                    :limit=1
                                    :ffileList="ShopArticle.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>物品信息</span>
                                </template>
                                <el-descriptions
                                    class="margin-top"
                                    :column="1"
                                    :labelStyle="{'text-align': 'center','width':'120px'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>物品状态</span>
                                        </template>
                                        <el-select
                                            v-model="ShopArticle.state"
                                            filterable
                                            clearable
                                            class="state_c"
                                        >
                                            <el-option
                                                v-for="item in AllState"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>抽奖物品</span>
                                        </template>
                                        <el-select
                                            v-model="ShopArticle.prize"
                                            filterable
                                            clearable
                                            class="prize_c"
                                        >
                                            <el-option
                                                v-for="item in AllPrize"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>物品名称</span>
                                        </template>
                                        <el-input v-model="ShopArticle.name" placeholder="请填写物品名称"></el-input>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>所需积分</span>
                                        </template>
                                        <el-input-number
                                            v-model="ShopArticle.score"
                                            :min="1"
                                            :max="999999"
                                            :step-strictly="true"
                                            class="input_num"
                                        ></el-input-number>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <span>物品说明</span>
                                </template>
                                <common-editor-component
                                    ref="editor"
                                    :fcontent="ShopArticle.note"
                                ></common-editor-component>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div v-else>
                        <el-descriptions
                            direction="vertical"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center','width':'50%'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>物品图片</span>
                                </template>
                                <img v-if="ShopArticle.picture.length" :src="ShopArticle.picture[0].url" height="200px" alt="">
                                <common-upload-component
                                    ref="upload"
                                    v-show="false"
                                    :disabled="true"
                                    :ffileList="ShopArticle.picture"
                                ></common-upload-component>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>物品信息</span>
                                </template>
                                <el-descriptions
                                    class="margin-top"
                                    :column="1"
                                    :labelStyle="{'text-align': 'center','width':'120px'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>物品状态</span>
                                        </template>
                                        <el-tag type="info" v-if="ShopArticle.state === 0">未上架</el-tag>
                                        <el-tag type="success" v-else-if="ShopArticle.state === 1">已上架</el-tag>
                                        <el-tag type="warning" v-else-if="ShopArticle.state === 2">限时兑换</el-tag>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>抽奖物品</span>
                                        </template>
                                        <el-tag type="info" v-if="ShopArticle.prize === 0">否</el-tag>
                                        <el-tag type="success" v-else-if="ShopArticle.prize === 1">是</el-tag>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>物品名称</span>
                                        </template>
                                        <span>{{ShopArticle.name}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>所需积分</span>
                                        </template>
                                        <span>{{ShopArticle.score}}</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2 :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                <template slot="label">
                                    <span>物品说明</span>
                                </template>
                                <div class="el-tiptap-editor__content" v-html="ShopArticle.note"></div>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="ShopArticleDel('ShopArticleEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeShopArticleDialog('ShopArticleEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('ShopArticleEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('ShopArticleEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeShopArticleDialog('ShopArticleEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('ShopArticleEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="$emit('cancel_edit')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ShopArticleAddRequest,ShopArticleDelRequest,ShopArticleEditRequest } from '@/network/shop/ShopArticle.js'
import CommonUploadComponent from '@/components/common/CommonUploadComponent'
import CommonEditorComponent from '@/components/common/CommonEditorComponent'

export default {
    name: 'ShopArticleEditComponent',
    data() {
        return {
            login_loading: false,
            ShopArticle: {
                state: '',
                prize: '',
                name: '',
                picture: [],
                score: 9999,
                note: ''
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '物品详情'
            } else if (this.show_type === 1) {
                return '添加物品'
            } else {
                return '修改物品'
            }
        },
    },
    methods: {
        closeShopArticleDialog(formName) {
            this.resetForm(formName)
            this.$emit('exitShopArticleDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ShopArticleInsert(formName)
            })
        },
        ShopArticleInsert(formName) {
            this.login_loading = true
            this.ShopArticle.picture = this.$refs.upload.fileList
            this.ShopArticle.note = this.$refs.editor.content
            ShopArticleAddRequest(this.ShopArticle)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitShopArticleDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs.upload.hideUpload = false
            this.$refs.upload.fileList = []
            this.ShopArticle = {
                state: '',
                prize: '',
                name: '',
                picture: [],
                score: 9999,
                note: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ShopArticleEdit(formName)
            })
        },
        ShopArticleEdit(formName) {
            this.login_loading = true
            this.ShopArticle.id = this.id
            this.ShopArticle.picture = this.$refs.upload.fileList
            this.ShopArticle.note = this.$refs.editor.content
            ShopArticleEditRequest(this.ShopArticle)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitShopArticleDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.ShopArticle.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        ShopArticleDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    ShopArticleDelRequest(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeShopArticleDialog(formName))
                })
                .catch((err) => {})
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        shop_article_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        AllState: {
            type: Array,
            default() {
                return []
            }
        },
        AllPrize: {
            type: Array,
            default() {
                return []
            }
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonUploadComponent,
        CommonEditorComponent
    },
    watch: {
        shop_article_details_data(newVal) {
            this.ShopArticle = newVal
        },
    },
}
</script>

<style lang='less' scoped>
.state_c,.prize_c,.input_num {
    width: 100%
}
</style>