<template>
    <div v-loading.fullscreen.lock="loading">
        <shop-article-search-bar-component
            AddAuth="新增物品"
            :AllState="AllState"
            :AllPrize="AllPrize"
            @search="get_shop_article_index"
            @addTeam="addTeam"
        ></shop-article-search-bar-component>
        <common-table-component
            details_auth="物品详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_shop_article"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_shop_article_index"
        ></common-page-component>
        <shop-article-edit-component
            edit_auth="修改物品"
            del_auth="删除物品"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :shop_article_details_data="fshop_article_details_data"
            :AllState="AllState"
            :AllPrize="AllPrize"
            @exitShopArticleDialog="dialogExit"
            @search="get_shop_article_index"
            @show_edit="show_edit"
            @cancel_edit="cancel_edit"
            @details_row="details_shop_article"
        ></shop-article-edit-component>
    </div>
</template>

<script>
import { ShopArticleIndexRequest,ShopArticleDetailsRequest } from '@/network/shop/ShopArticle.js'

import ShopArticleSearchBarComponent from '@/components/shop/ShopArticle/ShopArticleSearchBarComponent'
import ShopArticleEditComponent from '@/components/shop/ShopArticle/ShopArticleEditComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name:'',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                state: '',
                prize: '',
                name: '',
                page: 1,
                limit: 10
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'avatar',
                    label: '图片',
                    width: '100px',
                    html: (row) => {
                        return '<img src="'+row.picture[0]['url']+'" height="50px"></img>'
                    }
                },
                {
                    prop: 'state',
                    label: '状态',
                    width: '90px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '未上架'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '已上架'
                            }
                        } else if(d===2) {
                            return {
                                type: 'warning',
                                filter_data: '限时兑换'
                            }
                        }
                    }
                },
                {
                    prop: 'prize',
                    label: '抽奖物品',
                    width: '120px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '否'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '是'
                            }
                        }
                    }
                },
                {
                    prop: 'name',
                    label: '物品名称',
                    minWidth: '200px'
                },
                {
                    prop: 'score',
                    label: '所需积分',
                    minWidth: '100px'
                },
                {
                    prop: 'create_staff_name',
                    label: '创建人',
                    minWidth: '200px'
                },
                {
                    prop: 'create_time',
                    label: '最后修改时间',
                    minWidth: '160px'
                }
            ],
            fshop_article_details_data: {},
            AllState:[
                {id:0,name:'未上架'},
                {id:1,name:'已上架'},
                {id:2,name:'限时兑换'}
            ],
            AllPrize: [
                {id:0,name:'否'},
                {id:1,name:'是'}
            ]
        }
    },
    computed:{},
    methods:{
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        cancel_edit() {
            this.edit_type = 0
        },
        get_shop_article_index(param = {}) {
            this.loading = true
            this.cond.state = param.state ?? this.cond.state
            this.cond.prize = param.prize ?? this.cond.prize
            this.cond.name = param.name ?? this.cond.name
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            ShopArticleIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
                .finally()
        },
        details_shop_article(id) {
            this.fid = id
            this.fdialogFormVisible = true
            ShopArticleDetailsRequest(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fshop_article_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_shop_article_index()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        ShopArticleSearchBarComponent,
        ShopArticleEditComponent,
        CommonPageComponent,
        CommonTableComponent
    },
    watch:{},
}
</script>

<style lang='less' scoped></style>